<template>
  <div>
    <v-card
      flat
    >
      <v-toolbar dense dark color="primary">
        <v-toolbar-title><h4 class="font-weight-light">NEW PARTNERSHIP</h4>
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" class="multi-col-validation mt-6">
        <v-card-text>
          <v-col
            md="3"
            cols="12"
          ></v-col>
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-select
                dense
                outlined
                v-model="partnership_category"
                :items="['SCHOOL','LGU','GOVERNMENT AGENCY','FOUNDATION','COMMUNITY ORG','BUSINESS']"
                label="Category"
                required
                :rules="rules.combobox_rule"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="email_address"
                label="Email Address"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="date_registered"
                label="Date of Registration"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner"
                label="Name of the Partner/Business"
                dense
                outlined
                :rules="rules.default_max_255_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative"
                label="Name of the Partner/Business Representative"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_position"
                label="Position of the Partner/Business Representative"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_contact"
                label="Contact of the Partner/Business Representative"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative2"
                label="Name of the Partner/Business Representative 2"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_position2"
                label="Position of the Partner/Business Representative 2"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_contact2"
                label="Contact of the Partner/Business Representative 2"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>

            <v-col
              md="12"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative3"
                label="Name of the Partner/Business Representative 3"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_position3"
                label="Position of the Partner/Business Representative 3"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
              ></v-text-field>
            </v-col>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="name_of_partner_representative_contact3"
                label="Contact of the Partner/Business Representative 3"
                dense
                outlined
                :rules="rules.default_max_45_character_and_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              md="4"
              cols="12"
            >
              <v-text-field
                v-model="merchant_percentage"
                label="Merchant Percentage"
                dense
                outlined
                :rules="rules.default_no_empty_rule"
                type="number"
              ></v-text-field>
            </v-col>

          </v-row>
        </v-card-text>
      </v-form>
      <!-- alert -->
      <v-col cols="12" v-show="alert">
        <v-alert
          color="warning"
          text
          class="mb-0"
        >
          <div class="d-flex align-start">
            <v-icon color="warning">
              {{ icons.mdiAlertOutline }}
            </v-icon>

            <div class="ms-3">
              <p class="text-base font-weight-medium mb-1">
                {{alert_message}}
              </p>
            </div>
          </div>
        </v-alert>
      </v-col>

      <v-col cols="12">
        <v-btn
          color="primary"
          class="me-3 mt-4"
          @click="save_member"
          v-if="!saving"
        >
          Save changes
        </v-btn>
        <v-progress-circular
          :size=50
          :width="5"
          color="primary"
          indeterminate
          v-else
        ></v-progress-circular>
      </v-col>
    </v-card>

    <snack-bar-dialog
      :snackbar_flag="this.snackbar"
      :color="this.snackbar_color"
      :snackbar_text="this.snackbar_text"
    />
  </div>
</template>

<script>
  import {mdiAlertOutline, mdiCloudUploadOutline, mdiAccountPlus, mdiCash} from '@mdi/js'
  import {mapGetters, mapActions} from "vuex";
  import moment from 'moment'
  import snackBarDialog from '@/components/dialogs/notifications_dialog/Snackbar'

  const initialState = () => {
    return {
      saving: false,
      alert: false,
      alert_message: '',
      merchant_percentage: '0',

      croppieImage1: '',
      cropped1: false,
      croppieImage2: '',
      cropped2: false,
      croppieImage3: '',
      cropped3: false,
      avatarImg: require('@/assets/images/avatars/1.png'),

      donors_dialog: false,
      volunteers_dialog: false,

      location: '',
      name_of_partner: '',
      recipients: '',
      date_registered: '',
      email_address: '',
      expenses: 0,
      list_of_donors: [],
      list_of_volunteers: [],
      list_of_expenses: [],

      date_donated: '',
      name_of_sponsor: '',
      kind: '',
      kind_donated: '',
      donate_amount: 0,

      is_registered: true,

      search_member: '',
      partnership_category: '',
      search_items: [],
      idd_of_donor: 0,
      narratives: '',
      expense_dialog: false,
      exp_particulars: '',
      exp_description: '',
      exp_amount: '',
      name_of_partner_representative: '',
      name_of_partner_representative_position: '',
      name_of_partner_representative_contact: '',
      name_of_partner_representative2: '',
      name_of_partner_representative_position2: '',
      name_of_partner_representative_contact2: '',
      name_of_partner_representative3: '',
      name_of_partner_representative_position3: '',
      name_of_partner_representative_contact3: '',
    }
  }

  export default {
    components: {
      snackBarDialog,
    },
    props: {
      value: Object,
      is_edit: Boolean,
    },
    setup() {
      return {
        show: false,

        icons: {
          mdiAlertOutline,
          mdiCloudUploadOutline,
          mdiAccountPlus,
          mdiCash,
        },
      }
    },
    mounted() {
      if (this.value != null) {
        if (Object.keys(this.value).length > 0) {
          this.name_of_partner = this.value.name_of_partner
          this.email_address = this.value.email_address
          this.date_registered = this.value.date_registered
          this.partnership_category = this.value.partnership_category
          this.name_of_partner_representative = this.value.name_of_partner_representative
          this.name_of_partner_representative_position = this.value.name_of_partner_representative_position
          this.name_of_partner_representative_contact = this.value.name_of_partner_representative_contact
          this.name_of_partner_representative2 = this.value.name_of_partner_representative2
          this.name_of_partner_representative_position2 = this.value.name_of_partner_representative_position2
          this.name_of_partner_representative_contact2 = this.value.name_of_partner_representative_contact2
          this.name_of_partner_representative3 = this.value.name_of_partner_representative3
          this.name_of_partner_representative_position3 = this.value.name_of_partner_representative_position3
          this.name_of_partner_representative_contact3 = this.value.name_of_partner_representative_contact3
        }
      }
    },
    data() {
      return initialState()
    },
    watch: {
      search_member: function () {
        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          if (this.search_member === '') {
            this.search_items = []
          } else {
            this.searching_search_items(this.search_member)
          }
        }, 500)
      },
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['branch_id', 'district_id', 'district_name', 'user_id', 'month_start', 'month_end']),
      ...mapGetters('system_data', ['snackbar', 'snackbar_color', 'snackbar_text']),
    },
    methods: {
      ...mapActions('list_of_partnerships', ['register_new_partnership']),
      ...mapActions('system_data', ['change_snackbar']),
      async save_member() {
        this.saving = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('created_id', this.user_id);
          data.append('merchant_percentage', this.merchant_percentage);
          data.append('name_of_partner', this.name_of_partner.toUpperCase());
          data.append('email_address', this.email_address);
          data.append('date_registered', this.date_registered);
          data.append('partnership_category', this.partnership_category);
          data.append('name_of_partner_representative', this.name_of_partner_representative.toUpperCase());
          data.append('name_of_partner_representative_position', this.name_of_partner_representative_position);
          data.append('name_of_partner_representative_contact', this.name_of_partner_representative_contact);
          data.append('name_of_partner_representative2', this.name_of_partner_representative2.toUpperCase());
          data.append('name_of_partner_representative_position2', this.name_of_partner_representative_position2);
          data.append('name_of_partner_representative_contact2', this.name_of_partner_representative_contact2);
          data.append('name_of_partner_representative3', this.name_of_partner_representative3.toUpperCase());
          data.append('name_of_partner_representative_position3', this.name_of_partner_representative_position3);
          data.append('name_of_partner_representative_contact3', this.name_of_partner_representative_contact3);
          data.append('id', this.is_edit ? this.value.id : '');
          data.append('is_edit', this.is_edit ? 1 : 0);
          this.register_new_partnership(data)
            .then(response => {
              var color = 'success'
              if (response.status === 201) {
                color = 'error'
              }
              this.change_snackbar({
                show: true,
                color: color,
                text: response.data,
              })
              this.saving = false
              this.$emit('data', true)
            })
            .catch(error => {
              this.alert = true
              this.alert_message = error
              console.log(error)
              this.saving = false
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving = false
        }
      }
    }
  }
</script>
